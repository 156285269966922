<template>
	<div>
		<div style="margin-top: 5%;padding: 5%;background: #0b89e5">
			<div style="display: grid;grid-template-columns: 1fr 1fr;width: 100%;">
				<div>
					借款人(企业):{{ loan_message.borrower }}
				</div>
				<div>
					借款金额:{{ loan_message.loanAmount }} (利率:{{ loan_message.interestRate }})
				</div>
			</div>
			<el-divider/>
			<div style="display: grid;grid-template-columns: 1fr 1fr;width: 100%">
				<div>
					借款日期:{{ loan_message.loanDate.slice(0, 10) }}
				</div>
				<div>
					约定偿还日期:{{ loan_message.reimburseDate.slice(0, 10) }}
				</div>
			</div>
			<el-divider/>
			<!--	提示截至当日应还本金、利息-->
			<div>
				<div style="margin-bottom: 5%">
					截止今日应还本金：{{ parseFloat(principal_interest[0].toFixed(2)) }} 元
				</div>
				<div>
					截止今日应还利息：{{ parseFloat(principal_interest[1].toFixed(2)) }} 元
				</div>
			</div>
		</div>

	<!--	实际还款数额-->
		<div style="margin-top: 10%">
			<div>
				<div>
					<el-tag>实际偿还本金:</el-tag>
					<el-input v-model="principal" placeholder="请输入实际偿还本金" clearable />
				</div>
				<div style="margin-top: 5%">
					<el-tag>实际偿还利息:</el-tag>
					<el-input v-model="interest" placeholder="请输入实际偿还利息" clearable />
				</div>
			</div>
		</div>

		<div style="margin-top: 20%">
			<el-button type="primary" style="width: 100%" @click="submitLoan">提交还款</el-button>
		</div>


	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "Loan_repayment",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			main_id: '',
			loan_message: '',
			principal_interest: '',
			principal: '',
			interest: ''
		}
	},
	created() {
		if (!this.$route.params.key) {
			ElMessage.error('非法访问!')
			this.$router.push("/loan_index")
		} else {
			this.main_id = this.$route.params.key
			this.getLoanMessageById()
		}
	},
	methods: {
		getLoanMessageById() {
			this.axios.get('/loanMain/getLoanMessageById', (response) => {
				this.loan_message = response.obj
				this.principal_interest = response.obj2
			}, {
				id: this.main_id
			})
		},
		submitLoan(){
			if (this.principal === ''){
				this.principal = 0
			}
			if (this.interest === ''){
				this.interest = 0
			}
			this.axios.post('/loanRepaymentSecondary/repaymentMessage', (response) => {
				if (response.obj){
					ElMessage.success('还款成功!')
					const time = Date.parse(new Date());
					this.$router.push({
						name: 'Loan_index',
						params: {
							key: time,
							user_id: this.$route.params.user_id
						}
					});
				}
			}, {
				principal: this.principal,
				interest: this.interest,
				should_principal: parseFloat(this.principal_interest[0].toFixed(2)),
				should_interest: parseFloat(this.principal_interest[1].toFixed(2)),
				main_id: this.main_id,
				user_id: this.$route.params.user_id
			})
		}
	}
}
</script>

<style>

</style>